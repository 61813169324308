import * as react from "react";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import moment from "moment";
import SearchIcon from "../../../assets/search.svg";
import { useAppSelector } from "../../../../app/hooks";
import { selectAccount, selectAllTokens } from "../../../../store/mainSlice";
import { IRace, TokenMetadata, TokenType } from "../../../../types";
import InfoWhite from "../../../assets/info-white.png";
import { OrdinalMap } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  calculatePrizeFromStructure,
  formatTokenName,
  getTimeToRace,
  token2DecimalDisplay,
} from "../../../../tools/helper";
import {
  getTokenImage,
  getTokenMetadata,
  useCurrentRace,
  useUserHistory,
} from "../../../../services/queries";
import "./style.scss";
import { BoxPositionMap } from "../racesummary";
import { trimBloodlineAttribute } from "../../kennel";
import PrizeTypeBadge from "../../../common/PrizeTypeBadge";
import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CreateComman } from "../../../../hooks/CommanContext";

interface IProps {
  data: any;
  isLoading?: boolean;
  isError?: boolean;
}

export default function UserHistory(props: IProps) {
  const userAddress = useAppSelector(selectAccount);
  const allTokens = useAppSelector(selectAllTokens);
  const { data: historyData } = useUserHistory(userAddress);
  console.log("historyData", historyData);

  const [showWatch3dHover, setShowWatch3dHover] = react.useState(false);
  const navigate = useNavigate();
  const [filterString, setFilterString] = react.useState("");
  const [showAll, setShowAll] = react.useState(false);
  const [popupmodel, setPopupmodel] = react.useState(false);
  const [modelshow, setModelshow] = react.useState<
    | {
        raceId: string;
        tokenFamily: string;
        tokenId: number;
      }
    | undefined
  >();

  const [showSummary, setShowSummary] = react.useState(false);
  const [receId, setRaceId] = react.useState<any>();
  const [races, setRaces] = react.useState<IRace[]>([]);
  const { data } = props;
  const {
    data: currentRaceData,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useCurrentRace(receId ? receId : "");
  const { setmodelshow } = react.useContext(CreateComman);
  const { uuid } = useParams(); //RaceID
  const [tokenData, setTokenData] = react.useState<
    TokenMetadata[] | any | undefined
  >(undefined);

  react.useEffect(() => {
    if (uuid) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [uuid]);
  const [timer, setTimer] = react.useState(0);
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (data && data.data.length > 0 && Array.isArray(data.data)) {
      //function to sort data by startTime property
      let copy = data.data.filter((race: IRace) => !race.cancelled);
      const sortData = (copy as IRace[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [data]);

  const [raceData, setRaceData] = react.useState<IRace | undefined>(undefined);

  react.useEffect(() => {
    if (currentRaceData && isSuccess) setRaceData(currentRaceData.data);
  }, [currentRaceData, isSuccess]);

  const updateTokenInfo = react.useCallback(async () => {
    if (!raceData) return;
    let toUpdate: any[] = Array(raceData.maxEntrants || 8).fill(0);
    if (raceData.entrants) {
      for (let i = 0; i < raceData.entrants.length; i++) {
        let boxNumber = raceData.entrants[i].joinPos;
        let tokenInfo = await getTokenMetadata(
          raceData.entrants[i].tokenId,
          raceData.entrants[i].tokenFamily
        );

        toUpdate[i] = {
          ...tokenInfo,
          kennelName: raceData.entrants[i].kennel || "",
          boxNumber: boxNumber,
        };
      }
    }

    setTokenData(toUpdate);
  }, [raceData]);

  react.useEffect(() => {
    updateTokenInfo();
  }, [raceData]);

  let count = 0;
  const handleClick = (check: {
    raceId: string;
    tokenFamily: string;
    tokenId: number;
  }) => {
    setModelshow(check);
    setRaceId(check.raceId);
  };
  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: IRace, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }
                  if (count <= 5)
                    return (
                      <div
                        className="box"
                        key={race.raceId}
                        onClick={() => {
                          setmodelshow(race.raceId);
                          navigate("/race");
                        }}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </div>
                    );
                }
              })
            ) : (
              <div className="none loading-data">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
          <span className="sides">See All</span>
        </div>
        <div
          className="race-classes race-history-classes"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title race-hostory">
            <div className="history">
              <p>MY RACING HISTORY</p>
              <img src={InfoWhite} alt="infowhite"></img>
            </div>
            <div className="search-results ">
              <img
                src={SearchIcon}
                className="search-icon"
                alt="SearchIcon"
              ></img>
              <input
                type="text"
                placeholder="SEARCH"
                onChange={(e) => setFilterString(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="outcome-switch">
            <span className="switch-msg">
              {showAll ? "Hide Outcomes" : "Show Outcomes"}
            </span>
            <Switch onChange={() => setShowAll(!showAll)}></Switch>
          </div>
        </div>

        <div className="history-list">
          <div className="titles summary-columns">
            <div>PLACE</div>
            <div>GREYHOUND</div>
            <div>RACE NAME</div>
            <div>DISTANCE</div>
            <div>DATE</div>
          </div>

          {!showSummary && (
            <>
              <div className="race-history">
                {Array.isArray(historyData?.data) ? (
                  historyData?.data.map((race: IRace, index: number) => {
                    return race.entrants?.map((entrant: any, index2: any) => {
                      const token = race.fee_token
                        ? race.fee_token
                        : TokenType.USDT;
                      const tokenDisplayName = formatTokenName(token);
                      let percentage_array = [
                        ...Array(race.maxEntrants || 8).keys(),
                      ];

                      if (entrant.ownerAddress === userAddress) {
                        let outcomePosition =
                          race?.outcome?.findIndex(
                            (item: any) =>
                              item.tokenId === entrant.tokenId &&
                              item.tokenFamily === entrant.tokenFamily
                          )! + 1;

                        let tokenn = allTokens?.find(
                          (item) =>
                            item.tokenId === entrant.tokenId &&
                            item.tokenFamily === entrant.tokenFamily
                        );

                        if (race.cancelled) {
                          outcomePosition = 0;
                        }
                        if (!tokenn) {
                          return;
                        }

                        let check = {
                          raceId: race.raceId,
                          tokenFamily: tokenn.tokenFamily,
                          tokenId: tokenn.tokenId,
                        };

                        const shouldShowDetailDropdown =
                          modelshow?.raceId === check.raceId &&
                          modelshow?.tokenFamily === check.tokenFamily &&
                          modelshow?.tokenId === check.tokenId;

                        return !shouldShowDetailDropdown ? (
                          <div
                            // to={`/race/${race.raceId}`}
                            className="history-row"
                            key={race.raceId + index2}
                            onClick={() => {
                              handleClick({
                                raceId: race.raceId,
                                tokenFamily: entrant.tokenFamily,
                                tokenId: entrant.tokenId,
                              });
                            }}
                          >
                            <div className="grid-item ">
                              <div className="position">
                                {showAll
                                  ? outcomePosition
                                    ? OrdinalMap[
                                        outcomePosition as keyof typeof OrdinalMap
                                      ]
                                    : "A"
                                  : outcomePosition
                                  ? "?"
                                  : "A"}
                              </div>
                            </div>
                            <div className="name grid-item">
                              {tokenn?.metadata!.name}
                            </div>
                            <div className="race-titles">
                              <span>
                                {race.raceName}
                                {race.cancelled && " - Abandoned"}
                              </span>
                            </div>

                            <div className="distance grid-item">
                              {race.raceDistance}m
                            </div>
                            <div className="date grid-item">
                              {moment(race.startTime).format(
                                "DD MMM, YYYY. hh:mm"
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="popup-race-row">
                              <div
                                onClick={() => setModelshow(undefined)}
                                className="close"
                              >
                                <CloseIcon />
                              </div>
                              <div className="header">
                                <div className="left">
                                  <h6>
                                    {race.raceName}
                                    <span>{race.raceLocation}</span>
                                  </h6>
                                  <span
                                    onClick={() =>
                                      navigator.clipboard.writeText(race.raceId)
                                    }
                                  >
                                    <ContentCopyIcon
                                      sx={{
                                        color: "#6a6f76",
                                        ":hover": { color: "#FFF" },
                                      }}
                                    />
                                  </span>
                                </div>

                                <div className="right-main">
                                  <div className="img-cotain">
                                    <div>
                                      <div
                                        onClick={() => {
                                          window.scrollTo(0, 0);
                                          navigate(`/race/${race.raceId}`);
                                        }}
                                        className="watch-3d"
                                        style={{
                                          backgroundImage: "url(/2d.png)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          backgroundSize: "cover",
                                        }}
                                      >
                                        Watch 2d
                                        <OpenInNewIcon />
                                      </div>
                                      {race.entrants.length ===
                                        race.maxEntrants ||
                                      (race.maxEntrants === 8 &&
                                        race.entrants.length >= 2) ? (
                                        <a
                                          className="watch-3d"
                                          onMouseEnter={() =>
                                            setShowWatch3dHover(true)
                                          }
                                          onMouseLeave={() =>
                                            setShowWatch3dHover(false)
                                          }
                                          href={`${`${process.env.REACT_APP_3D_URL}/race/${race.raceId}`}`}
                                          style={{
                                            backgroundImage: "url(/3d.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",

                                            position: "relative",
                                            textDecoration: "none",
                                            color: "white",
                                          }}
                                        >
                                          Watch 3D <OpenInNewIcon />
                                          {showWatch3dHover &&
                                            race.entrants.length !==
                                              race.maxEntrants && (
                                              <div className="custom-tooltip">
                                                <div className="innerdiv">
                                                  <label className="">
                                                    Available once race is
                                                    filled
                                                  </label>
                                                </div>
                                              </div>
                                            )}
                                        </a>
                                      ) : (
                                        <div
                                          className="watch-3d"
                                          onMouseEnter={() =>
                                            setShowWatch3dHover(true)
                                          }
                                          onMouseLeave={() =>
                                            setShowWatch3dHover(false)
                                          }
                                          style={{
                                            backgroundImage: "url(/3d.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",

                                            position: "relative",
                                          }}
                                        >
                                          Watch 3D <OpenInNewIcon />
                                          {showWatch3dHover &&
                                            race.entrants.length !==
                                              race.maxEntrants && (
                                              <div className="custom-tooltip">
                                                <div className="innerdiv">
                                                  <label className="">
                                                    Available once race is
                                                    filled
                                                  </label>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="right">
                                    <div className="avtar">
                                      <h6>Entry Fees:-</h6>
                                      <span>
                                        {race.entryFee === "0" || !race.entryFee
                                          ? "Free"
                                          : `${token2DecimalDisplay(
                                              race.entryFee,
                                              token
                                            )} ${tokenDisplayName}`}{" "}
                                      </span>
                                    </div>

                                    <div
                                      onClick={() => setPopupmodel(!popupmodel)}
                                      className="popover_p"
                                    >
                                      <p>Prize pool dividends </p>
                                      {popupmodel ? (
                                        <ArrowUpwardIcon />
                                      ) : (
                                        <ArrowDownwardIcon />
                                      )}

                                      {popupmodel && (
                                        <div
                                          // ref={hoverBoxRef}
                                          style={{ left: "-11.8rem" }}
                                          className="custom-tooltip pricepool-tooltip-cover prize-pooltooltip"
                                        >
                                          <div className="innerdiv pricepool-tooltip">
                                            {percentage_array.map(
                                              (_, index) => {
                                                if (index === 0) {
                                                  return (
                                                    <div
                                                      className="inner-wrapper"
                                                      key={index}
                                                    >
                                                      <span className="rank-gold rank">
                                                        1ST
                                                      </span>
                                                      <span>
                                                        {calculatePrizeFromStructure(
                                                          race.prizePercentages[
                                                            index
                                                          ] || 0,
                                                          race.prizePool || "0",
                                                          token
                                                        ).toString()}
                                                      </span>
                                                      <span>
                                                        {tokenDisplayName}
                                                      </span>
                                                    </div>
                                                  );
                                                }

                                                if (index === 1) {
                                                  return (
                                                    <div
                                                      className="inner-wrapper"
                                                      key={index}
                                                    >
                                                      <span className="rank-silver rank">
                                                        2ND
                                                      </span>
                                                      <span>
                                                        {calculatePrizeFromStructure(
                                                          race.prizePercentages[
                                                            index
                                                          ] || 0,
                                                          race.prizePool || "0",
                                                          token
                                                        ).toString()}
                                                      </span>
                                                      <span>
                                                        {tokenDisplayName}
                                                      </span>
                                                    </div>
                                                  );
                                                }
                                                if (index === 2) {
                                                  return (
                                                    <div
                                                      className="inner-wrapper"
                                                      key={index}
                                                    >
                                                      <span className="rank-bronze rank">
                                                        3RD
                                                      </span>
                                                      <span>
                                                        {calculatePrizeFromStructure(
                                                          race.prizePercentages[
                                                            index
                                                          ] || 0,
                                                          race.prizePool || "0",
                                                          token
                                                        ).toString()}
                                                      </span>
                                                      <span>
                                                        {tokenDisplayName}
                                                      </span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <react.Fragment key={index}>
                                                      <div className="inner-wrapper">
                                                        <span className="rank">
                                                          {
                                                            OrdinalMap[
                                                              (index +
                                                                1) as keyof typeof OrdinalMap
                                                            ]
                                                          }
                                                        </span>
                                                        <span>
                                                          {calculatePrizeFromStructure(
                                                            race
                                                              .prizePercentages[
                                                              index
                                                            ] || 0,
                                                            race.prizePool ||
                                                              "0",
                                                            token
                                                          ).toString()}
                                                        </span>
                                                        <span>
                                                          {tokenDisplayName}
                                                        </span>
                                                      </div>
                                                    </react.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                          </div>
                                          <hr />
                                          <p
                                            style={{
                                              margin: "0px 0px 10px 0px",
                                              width: "300px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            XP is awarded to every dog that
                                            enters a race that runs, except for
                                            those that are levelled.
                                          </p>
                                          <a
                                            style={{
                                              color: "#13bc41",
                                              textDecoration: "none",
                                              fontWeight: "800",
                                            }}
                                            href="https://app.gitbook.com/o/KVg36gIWyzxW8xhAhxon/s/ZtZWoPvx9kAeLgV3rhDX/betnft/experience-points-xp"
                                          >
                                            Click here for XP payout Info
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row-status">
                                <div>
                                  <div>
                                    <p>Event Type:-</p>
                                    <span>{race.raceClass}</span>
                                    <PrizeTypeBadge
                                      prizePercentages={race.prizePercentages}
                                    ></PrizeTypeBadge>
                                  </div>

                                  <div>
                                    <p>Distance:- </p>
                                    <span>{race.raceDistance}</span>
                                  </div>

                                  <div>
                                    <p>status:- </p>
                                    <span>{race.status}</span>
                                  </div>

                                  <div>
                                    <p className="starts">Starts :- </p>
                                    <span>
                                      {" "}
                                      {moment(race.startTime).format(
                                        "DD MMM, YYYY. hh.mm a"
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <p>Registered:-</p>
                                  <span>
                                    {race.entrants
                                      ? `${race.entrants.length}/${
                                          race.maxEntrants || 8
                                        }`
                                      : `0/${race.maxEntrants || 8}`}
                                  </span>
                                </div>
                              </div>

                              <hr className="hr" />
                              <div className="table">
                                <div className="table-header">
                                  <div>GATE</div>
                                  <div>GREYHOUND NAME</div>
                                  <div className="test">BLOODLINE/CLASS</div>
                                  <div>CAREER STATS</div>
                                  <div className="test">KENNEL NAME</div>
                                </div>

                                {tokenData?.map((token: any, index: number) => {
                                  if (!token) return;
                                  let bloodlineIndex =
                                    token.attributes.findIndex(
                                      (e: any) => e.trait_type === "Bloodline"
                                    );
                                  let [bloodlineTag, bloodlineName] =
                                    trimBloodlineAttribute(
                                      token.attributes[bloodlineIndex!].value
                                    );

                                  let matchingRaceToken: any =
                                    race.entrants.find(
                                      (e: any) => e.joinPos === token.boxNumber
                                    )!;

                                  return (
                                    <div key={index} className="table-body">
                                      <div className="id">
                                        {/* <span>{index}</span>{" "} */}
                                        <img
                                          className="table-index"
                                          src={
                                            BoxPositionMap[
                                              (token.boxNumber +
                                                1) as keyof typeof BoxPositionMap
                                            ]
                                          }
                                        ></img>
                                        <img
                                          src={
                                            token.image
                                              ? getTokenImage(token?.image)
                                              : "/"
                                          }
                                        ></img>
                                      </div>

                                      <div className="racehorse">
                                        <p>
                                          {token.name} -
                                          <div
                                            style={{
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              className="lx44"
                                              style={{ margin: "5px 0" }}
                                            >
                                              LV
                                              {matchingRaceToken?.currentLevel}
                                            </div>
                                            <div className="lx44_EXP">
                                              XP:{" "}
                                              <h6>
                                                {
                                                  matchingRaceToken?.currentLevelExperience
                                                }
                                                /200
                                              </h6>
                                            </div>
                                          </div>
                                        </p>
                                      </div>

                                      <div className="comman distance">
                                        {/* <p>{race.raceDists}</p> */}
                                        <div className="test">
                                          {bloodlineTag}/
                                          {matchingRaceToken.raceClass}
                                        </div>
                                      </div>

                                      <div className="comman date">
                                        <p>{`${matchingRaceToken?.career.firsts}/${matchingRaceToken?.career.seconds}/${matchingRaceToken?.career.thirds}`}</p>
                                      </div>

                                      <div className="comman distance">
                                        <div className="test">
                                          {token.kennelName || bloodlineTag}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <hr className="hr" />
                            </div>
                          </div>
                        );
                      }
                    });
                  })
                ) : (
                  <div className="none">
                    {props.isLoading ? "Loading races..." : "No Races Found"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
