import * as react from "react";

import { Token, TokenInfo } from "../../../types";
import {
  getTokenImage,
  usePromoteTokenRaceClass,
  useResumeTokenExperienceGain,
} from "../../../services/queries";
import LogoWShadow from "../../assets/logowshadow.png";
import { useAppSelector } from "../../../app/hooks";
import { selectAccount, selectSignature } from "../../../store/mainSlice";

interface IProps {
  tokenData: TokenInfo;
  token: Token;
  handleClose: () => void;
}

enum Action {
  PROMOTE,
  RESUME,
}

export default function PromoteToken(props: IProps) {
  const [message, setMessage] = react.useState("");
  //const promoteToken = usePromoteTokenRaceClass();
  const resumeExperienceGain = useResumeTokenExperienceGain();
  const account = useAppSelector(selectAccount);
  const [resuModelOpen, setResuModelOpen] = react.useState(false);
  const signature = useAppSelector(selectSignature);
  const handleAction = async (action: Action) => {
    if (action === Action.RESUME) {
      try {
        await resumeExperienceGain.mutateAsync({
          tokenFamily: props.tokenData.tokenFamily,
          tokenId: props.tokenData.tokenId,
          operation: "resume",
          signature: signature,
          address: account.toLowerCase(),
        });
        setMessage("Success");
      } catch (e: any) {
        console.log(e);
        setMessage(e.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="bg">
        <div className="close" onClick={() => props.handleClose()}>
          X
        </div>
        <div className="container">
          <img
            src={getTokenImage(props.tokenData.metadata?.image!)}
            alt="token"
            className="token"
          />

          <p
            style={{
              textAlign: "center",
              color: "#00b937e7",
              fontSize: "20px",
            }}
          >
            Congratulations!
          </p>

          <p
            style={{
              color: "white",
              marginRight: "auto",
              marginBottom: "0",
              margin: "10px",
            }}
          >
            {" "}
            Option 1- Stay in this class, compete for limited rewards against
            others with similar ability and not gain anymore XP or speed.
          </p>

          <button
            style={{
              color: "white",
              marginRight: "auto",
              marginBottom: "0",
              backgroundColor: "#00b937e7",
              border: "none",
              borderRadius: "10px",
              padding: "10px",
              fontSize: "16px",
              fontWeight: 700,
              cursor: "pointer",
              margin: "10px",
            }}
            onClick={() => props.handleClose()}
          >
            Option 1 <br /> Stay in class
          </button>
          {/* */}
          <p
            style={{
              color: "white",
              marginRight: "auto",
              marginBottom: "0",
              margin: "10px",
            }}
          >
            Option 2- Continue your journey, gain more XP (and speed) to the max
            Level 10 200/200 XP. You will then be automatically entered into the
            higher CLASS with larger stakes and rewards. This option is
            available at anytime (even after choosing option 1) but be mindful
            that once you choose to continue your racing journey there is no
            going back to the previous class.
          </p>

          <button
            className="save"
            style={{
              color: "white",
              marginRight: "auto",
              marginBottom: "0",
              backgroundColor: "#00b937e7",
              border: "none",
              borderRadius: "10px",
              padding: "10px",
              fontSize: "16px",
              fontWeight: 700,
              cursor: "pointer",
              width: "max-content",
              margin: "10px",
              height: "max-content",
            }}
            disabled={!resumeExperienceGain.isIdle}
            onClick={() => setResuModelOpen(true)}
          >
            Option 2 <br /> Resume XP Gain
          </button>
          {resumeExperienceGain.isLoading && (
            <div className="loading" style={{ color: "white" }}>
              Loading...
            </div>
          )}
          <div className="message" style={{ color: "white" }}>
            {message}
          </div>
        </div>
        <img src={LogoWShadow} alt="logo" className="kennel-logo" />
      </div>

      {/*  Resume EXP Gain model */}

      {resuModelOpen && (
        <div className="Resume_EXP_Gain_model">
          <div className="model">
            <p>Are you sure u want to proceed? </p>
            <div className="btn">
              <button
                onClick={() => {
                  handleAction(Action.RESUME); setResuModelOpen(false);
                }}
              >
                yes
              </button>
              <button onClick={() => setResuModelOpen(false)}>no</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const getNextClass = (currentClass: string) => {
  switch (currentClass) {
    case "G1":
      return "G1";
    case "G2":
      return "G1";
    case "G3":
      return "G2";
    case "G4":
      return "G3";
    default:
      return "";
  }
};
