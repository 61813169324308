import * as react from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import validator from "validator";
import { IRace, TokenType } from "../../../types";
import { Routes, Route, Link } from "react-router-dom";
import InfoWhite from "../../assets/info-white.png";
import RaceSummary from "./racesummary";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InfoIcon from "@mui/icons-material/Info";
import { useSearchParams } from "react-router-dom";
import {
  calculatePrizeFromStructure,
  formatTokenName,
  getTimeToRace,
  token2DecimalDisplay,
} from "../../../tools/helper";
import { Button, Divider } from "@mui/material";
import { OrdinalMap } from "../../utils";
import PrizeTypeBadge from "../../common/PrizeTypeBadge";
import { CreateComman } from "../../../hooks/CommanContext";

interface IProps {
  data: any;
  nextToRunData: any;
  isLoading?: boolean;
  isError?: boolean;
  modelshow?: string | number | undefined;
  setModelshow?: any;
}

export default function Resultlist(props: IProps) {
  const { data, nextToRunData, modelshow, setModelshow } = props;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [popupmodel, setPopupmodel] = react.useState(false);
  const [showWatch3dHover, setShowWatch3dHover] = react.useState(false);
  const [showSummary, setShowSummary] = react.useState(false);
  const [races, setRaces] = react.useState<IRace[]>([]);
  const { uuid } = useParams(); //RaceID
  const [filter, setFilter] = react.useState("");
  const [currentRaceIndex, setCurrentRaceIndex] = react.useState<
    number | undefined
  >(undefined);
  const [hoveredRaces, setHoveredRaces] = react.useState<{
    [key: string]: boolean;
  }>({});
  const { setmodelshow } = react.useContext(CreateComman);

  let percentage_array = [...Array(data?.data.maxEntrants || 8).keys()];

  const [showJoinPopup, setShowJoinPopup] = react.useState(false);

  const handleMouseEnter = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: true }));
  };

  const handleMouseLeave = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: false }));
  };

  react.useEffect(() => {
    if (uuid) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }

    if (data?.data.length > 0 && uuid) {
      const raceIndex = data?.data.findIndex(
        (item: any) => item.raceId === uuid
      );

      if (raceIndex >= 0) {
        setCurrentRaceIndex(raceIndex);
      } else {
        setCurrentRaceIndex(undefined);
      }
    }
  }, [uuid, data]);
  const [timer, setTimer] = react.useState(0);
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (
      nextToRunData &&
      nextToRunData.data.length > 0 &&
      Array.isArray(nextToRunData.data)
    ) {
      //function to sort data by startTime property
      let copy = nextToRunData.data.filter((race: IRace) => !race.cancelled);
      const sortData = (copy as IRace[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [nextToRunData]);
  const handleFilter = (filter: string) => {
    setFilter(filter);
  };
  let count = 0;

  const openPopup = async () => {
    setShowJoinPopup(true);
  };

  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: IRace, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }

                  if (count <= 5)
                    return (
                      <div
                        className="box"
                        key={race.raceId}
                        onClick={() => {
                          setmodelshow(race.raceId);
                          navigate("/race");
                        }}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </div>
                    );
                }
              })
            ) : (
              <div className="none loading-data">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
          <span className="sides">See All</span>
        </div>
        <div className="race-classes race-results-classes">
          <div className="title">
            RESULTS<img src={InfoWhite}></img>
          </div>
          <div className="classes">
            <div className="class-filter " onClick={() => handleFilter("")}>
              All
            </div>
            <div
              className="class-filter "
              onClick={() => handleFilter("Chance")}
            >
              Chance
            </div>
            <div className="class-filter " onClick={() => handleFilter("Open")}>
              Open
            </div>
            <div className="class-filter " onClick={() => handleFilter("G1")}>
              Class G1
            </div>
            <div className="class-filter " onClick={() => handleFilter("G2")}>
              Class G2
            </div>
            <div className="class-filter " onClick={() => handleFilter("G3")}>
              Class G3
            </div>
            <div className="class-filter " onClick={() => handleFilter("G4")}>
              Class G4
            </div>
            <div
              style={{ fontSize: "12px" }}
              className="class-filter "
              onClick={() => handleFilter("tournament")}
            >
              Tournament
            </div>
          </div>
        </div>
        <div className="list">
          <div className="titles race-result-titles summary-columns">
            <div>RACE NAME</div>
            <div>Event</div>
            <div>Distance</div>
            <div>Date</div>
            <div>Prize Pool</div>
          </div>

          {!showSummary && (
            <>
              <div className="races race-result-row">
                {Array.isArray(data?.data) ? (
                  data?.data
                    .filter((race: IRace) => {
                      if (filter === "tournament") {
                        return race.type?.includes(filter);
                      }
                      if (filter) {
                        return race.raceClass === filter;
                      }
                      return race;
                    })
                    .map((race: IRace, index: number) => {
                      const fee_token = race.fee_token
                        ? race.fee_token
                        : TokenType.USDT;
                      const tokenDisplayname = formatTokenName(fee_token);
                      const shouldShowWatch3D =
                        race.entrants.length === race.maxEntrants ||
                        (race.maxEntrants === 8 &&
                          race.entrants.length >= 2 &&
                          race.startTime < Date.now() + 60000);
                      if (Date.now() > 1000 * 40 + race.startTime)
                        return (
                          <>
                            {modelshow == race.raceId ? (
                              <div className="popup-race-row">
                                <div
                                  onClick={() => setModelshow(undefined)}
                                  className="close"
                                >
                                  <CloseIcon />
                                </div>

                                <div className="header">
                                  <div className="left">
                                    <h6>
                                      {race.raceName}
                                      <span>
                                        {race.cancelled && " - Abandoned"}
                                      </span>
                                    </h6>
                                    <span
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          data?.data._id
                                        )
                                      }
                                    >
                                      <ContentCopyIcon
                                        sx={{
                                          color: "#6a6f76",
                                          ":hover": { color: "#FFF" },
                                        }}
                                      />
                                    </span>
                                  </div>

                                  <div className="right-main">
                                    <div className="right">
                                      <div className="avtar">
                                        <h6>Entry Fees:-</h6>
                                        <span>
                                          {race.entryFee === "0" ||
                                          !race.entryFee
                                            ? "Free"
                                            : `${token2DecimalDisplay(
                                                race.entryFee,
                                                fee_token
                                              )} ${tokenDisplayname}`}{" "}
                                        </span>
                                      </div>

                                      <div
                                        onClick={() =>
                                          setPopupmodel(!popupmodel)
                                        }
                                        className="popover_p"
                                      >
                                        <p>Prize pool dividends</p>
                                        {popupmodel ? (
                                          <ArrowUpwardIcon />
                                        ) : (
                                          <ArrowDownwardIcon />
                                        )}

                                        {popupmodel && (
                                          <div
                                            // ref={hoverBoxRef}
                                            style={{ left: "-11.8rem" }}
                                            className="custom-tooltip pricepool-tooltip-cover prize-pooltooltip"
                                          >
                                            <div className="innerdiv pricepool-tooltip">
                                              {percentage_array.map(
                                                (_, index) => {
                                                  if (index === 0) {
                                                    return (
                                                      <div
                                                        className="inner-wrapper"
                                                        key={index}
                                                      >
                                                        <span className="rank-gold rank">
                                                          1ST
                                                        </span>
                                                        <span>
                                                          {calculatePrizeFromStructure(
                                                            race
                                                              .prizePercentages[
                                                              index
                                                            ] || 0,
                                                            race.prizePool ||
                                                              "0",
                                                            fee_token
                                                          ).toString()}
                                                        </span>
                                                        <span>
                                                          {tokenDisplayname}
                                                        </span>
                                                      </div>
                                                    );
                                                  }

                                                  if (index === 1) {
                                                    return (
                                                      <div
                                                        className="inner-wrapper"
                                                        key={index}
                                                      >
                                                        <span className="rank-silver rank">
                                                          2ND
                                                        </span>
                                                        <span>
                                                          {calculatePrizeFromStructure(
                                                            race
                                                              .prizePercentages[
                                                              index
                                                            ] || 0,
                                                            race.prizePool ||
                                                              "0",
                                                            fee_token
                                                          ).toString()}
                                                        </span>
                                                        <span>
                                                          {tokenDisplayname}
                                                        </span>
                                                      </div>
                                                    );
                                                  }
                                                  if (index === 2) {
                                                    return (
                                                      <div
                                                        className="inner-wrapper"
                                                        key={index}
                                                      >
                                                        <span className="rank-bronze rank">
                                                          3RD
                                                        </span>
                                                        <span>
                                                          {calculatePrizeFromStructure(
                                                            race
                                                              .prizePercentages[
                                                              index
                                                            ] || 0,
                                                            race.prizePool ||
                                                              "0",
                                                            fee_token
                                                          ).toString()}
                                                        </span>
                                                        <span>
                                                          {tokenDisplayname}
                                                        </span>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <react.Fragment
                                                        key={index}
                                                      >
                                                        <div className="inner-wrapper">
                                                          <span className="rank">
                                                            {
                                                              OrdinalMap[
                                                                (index +
                                                                  1) as keyof typeof OrdinalMap
                                                              ]
                                                            }
                                                          </span>
                                                          <span>
                                                            {calculatePrizeFromStructure(
                                                              race
                                                                .prizePercentages[
                                                                index
                                                              ] || 0,
                                                              race.prizePool ||
                                                                "0",
                                                              fee_token
                                                            ).toString()}
                                                          </span>
                                                          <span>
                                                            {tokenDisplayname}
                                                          </span>
                                                        </div>
                                                      </react.Fragment>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                            <hr />
                                            <p
                                              style={{
                                                margin: "0px 0px 10px 0px",
                                                width: "300px",
                                                fontWeight: "800",
                                              }}
                                            >
                                              XP is awarded to every dog that
                                              enters a race that runs, except
                                              for those that are levelled.
                                            </p>
                                            <a
                                              style={{
                                                color: "#13bc41",
                                                textDecoration: "none",
                                                fontWeight: "800",
                                              }}
                                              href="https://app.gitbook.com/o/KVg36gIWyzxW8xhAhxon/s/ZtZWoPvx9kAeLgV3rhDX/betnft/experience-points-xp"
                                            >
                                              Click here for XP payout Info
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row-status">
                                  <div>
                                    <div>
                                      <p>Event Type:-</p>
                                      <span>{race.raceClass}</span>
                                      <PrizeTypeBadge
                                        prizePercentages={race.prizePercentages}
                                      ></PrizeTypeBadge>
                                    </div>

                                    <div>
                                      <p>Distance:- </p>
                                      <span>{race.raceDistance}</span>
                                    </div>

                                    <div>
                                      <p>status:- </p>
                                      <span>{race.status}</span>
                                    </div>

                                    <div>
                                      <p className="starts">Starts :- </p>
                                      <span>
                                        {" "}
                                        {moment(race.startTime).format(
                                          "DD MMM, YYYY. hh.mm a"
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div>
                                    <p>Registered:-</p>
                                    <span>
                                      {race.entrants
                                        ? `${race.entrants.length}/${
                                            race.maxEntrants || 8
                                          }`
                                        : `0/${race?.maxEntrants || 8}`}
                                    </span>
                                  </div>
                                </div>

                                <hr className="hr" />
                                <div className="img-cotain-btn">
                                  <div
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      navigate(`/race/${race.raceId}`);
                                    }}
                                    style={{
                                      backgroundImage: "url(/2d.png)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      height: "150px",
                                    }}
                                  >
                                    <h6>Replay</h6>
                                    <h5>2D</h5>
                                  </div>
                                  <a
                                    onMouseEnter={() =>
                                      setShowWatch3dHover(true)
                                    }
                                    onMouseLeave={() =>
                                      setShowWatch3dHover(false)
                                    }
                                    href={`${`${process.env.REACT_APP_3D_URL}/race/${race.raceId}`}`}
                                    style={{
                                      backgroundImage: "url(/3d.png)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      height: "150px",
                                      position: "relative",
                                      textDecoration: "none",
                                      color: "white",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "100%",
                                      }}
                                    >
                                      <h6>Replay</h6>
                                      <h5>3D</h5>
                                    </div>
                                  </a>
                                </div>

                                <button
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate(
                                      `/race/${race.raceId}?showResult=true`
                                    );
                                  }}
                                  className="Show_Results"
                                >
                                  Show Result
                                </button>
                              </div>
                            ) : (
                              <div
                                // to={`/race/${race.raceId}?showResult=false`}

                                className="race-row summary-columns"
                                key={race.raceId}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  setModelshow(race.raceId);
                                }}
                              >
                                <div
                                  className="race-titles"
                                  // onClick={() => handleDetail(index)}
                                >
                                  {" "}
                                  <span>
                                    {race.raceName}
                                    {race.cancelled && " - Abandoned"}
                                  </span>
                                  <span>{race.raceLocation || "BetNFT"}</span>
                                </div>
                                {/* <div className="race-titles"> {race.raceId}</div> */}
                                <div className="grid-item entry-fee">
                                  {race.raceClass}
                                </div>

                                <div className="distance grid-item">
                                  {race.raceDistance}m
                                </div>
                                <div className="entry-fee grid-item">
                                  {moment(race.startTime).format(
                                    "DD MMM, YYYY. hh:mm"
                                  )}
                                </div>

                                <div
                                  onMouseEnter={() =>
                                    handleMouseEnter(race.raceId)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(race.raceId)
                                  }
                                  className={`prize-pool grid-item relative ${
                                    hoveredRaces[race.raceId] ? "hovered" : ""
                                  }`}
                                >
                                  {race.prizePool === "0" || !race.prizePool
                                    ? "Free"
                                    : `${token2DecimalDisplay(
                                        race.prizePool,
                                        fee_token
                                      )} ${tokenDisplayname}`}
                                  {hoveredRaces[race.raceId] && (
                                    <div className="custom-tooltip prize-pooltooltip">
                                      <div className="innerdiv">
                                        <span>
                                          Calculated by the number of entrants
                                          less house cost %
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        );
                    })
                ) : (
                  <div className="none">
                    {props.isLoading ? "Loading races..." : "No Races Found"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
