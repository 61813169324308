import "./style.scss";
import * as react from "react";
import Button from "@mui/material/Button";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAccount } from "../../store/mainSlice";
import { selectBalances } from "../../store/accountSlice";
import MenuIcon from "../assets/menu.png";
import { formatTokenAmount } from "../../tools/helper";
import { TokenType } from "../../types";
import { useHomeContent } from "../../services/queries";

export function formatAddress(address: string) {
  return (
    address.substring(0, 6) + "..." + address.substring(address.length - 4)
  );
}

interface IProps {
  currentUsername?: string;
  handleConnect: (...args: any[]) => void;
}

export default function Navbar({ currentUsername, handleConnect }: IProps) {
  //const [address, setaddress] = react.useState("Connect Wallet");
  const [disabled, setDisabled] = react.useState(false);
  const [connected, setConnect] = react.useState(false);
  const [mobileMenu, setMobileMenu] = react.useState(false);
  const { pathname } = useLocation();
  const account = useAppSelector(selectAccount);
  const balances = useAppSelector(selectBalances);

  react.useEffect(() => {
    if (account) {
      setDisabled(true);
    }
  }, [account]);

  const toggleNav = () => {
    setMobileMenu(mobileMenu == false ? true : false);
  };

  const disabledStyle: react.CSSProperties = { color: "white" };
  const homeContent = useHomeContent();

  const navref = react.useRef(null);

  return (
    <>
      <div ref={navref} className="navbar">
        <div className="homeContent_data">
          <h6 >Announcement</h6>
          {homeContent.data?.data && homeContent.data.data.homepage.content ? (
            <a href="https://mint.betnft.run/" target="_blank">
              {homeContent.data.data.homepage.content || ""}
            </a>
          ) : (
            <>else is runing.....</>
          )}
        </div>

        <div className="inner">
          <div className={`link-group mobile-${mobileMenu}`}>
            <div
              className={`nav-link ${
                pathname.includes("/race") ? "light-green" : ""
              }`}
            >
              <Link onClick={() => setMobileMenu(false)} to="/race">
                Racing
              </Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/breeding" ? "light-green" : ""
              }`}
            >
              <Link onClick={() => setMobileMenu(false)} to="/breeding">
                Breeding
              </Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/metaverse" ? "light-green" : ""
              }`}
            >
              <a href="https://mint.betnft.run/" target="_blank">
                Marketplace
              </a>
            </div>
            <div
              className={`nav-link ${
                pathname === "/rental" ? "light-green" : ""
              }`}
            >
              <Link onClick={() => setMobileMenu(false)} to="/rental">
                Rental
              </Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/kennel" ? "light-green" : ""
              }`}
            >
              <Link onClick={() => setMobileMenu(false)} to="/kennel">
                Kennel
              </Link>
            </div>
            <div
              className={`nav-link ${
                pathname === "/getting_started" ? "light-green" : ""
              }`}
            >
              <a
                href="https://betnft.gitbook.io/welcome-to-gitbook/betnft/getting-started"
                target="_blank"
              >
                Getting Started
              </a>
            </div>
            {/* <div
              className={`nav-link ${
                pathname === "/metaverse" ? "light-green" : ""
              }`}
            >
              <a
                href="https://wonderlandmeta.world/betameta/racetrack/index0.html"
                target="_blank"
              >
                Metaverse
              </a>
            </div> */}
            <div
              className={`nav-link ${
                pathname === "/metaverse" ? "light-green" : ""
              }`}
            >
              <a
                href="https://betnft.gitbook.io/welcome-to-gitbook/betnft/game-play"
                target="_blank"
              >
                Game Play
              </a>
            </div>
            <div
              className={`nav-link ${
                pathname === "/account" ? "light-green" : ""
              } wide`}
            >
              <Link onClick={() => setMobileMenu(false)} to="/account">
                {account && (
                  <div className="Undt_icon_contain">
                    Account <img className="USDT_icon" src="/USDT.png" alt="" />{" "}
                    ${formatTokenAmount(balances["USDT"], 6)}USDT -{" "}
                    <img className="USDT_icon" src="/BetNFT.png" alt="" />
                    {formatTokenAmount(balances[TokenType.BETNFT], 18)} $BetNFT
                  </div>
                )}
              </Link>
            </div>
          </div>

          <div className="end">
            <Button
              disabled={disabled}
              variant="contained"
              onClick={handleConnect}
              style={disabled ? disabledStyle : undefined}
              className="connect-btn"
            >
              {account ? <>{formatAddress(account)}</> : "Connect Wallet"}
            </Button>
            <Link to="/" onClick={() => setMobileMenu(false)} className="logo">
              <img src={Logo} alt="logo"></img>
            </Link>
            <div className="mobile-nav-icon">
              <img
                onClick={() => {
                  toggleNav();
                }}
                src={MenuIcon}
                alt="mobile menu"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
