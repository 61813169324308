import "./style.scss";
import { useHomeContent } from "./../../../services/queries";
import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import Popup from "../../common/Popup";
import Congratulations from "../races/Congratulations";

interface IProps {}

const card_data = [
  {
    id: 1,
    name: "Explore & Acquire",
    dec: [
      "Discover unique NFTs",
      "Acquire coveted assets For your digital greyhound racing journey",
    ],
  },
  {
    id: 2,
    name: "Choose Your Champion",
    dec: [
      "4 bloodlines with varying rarities.",
      "Each NFT boasts random attributes for an exciting, unpredictable race.",
    ],
  },
  {
    id: 3,
    name: "Enter the Race",
    dec: [
      "BetNFT holders can enter races using USDT or $BetNFT tokens.",
      "Compete For a slice of the prize pool and experience the thrill of victory.",
    ],
  },
  {
    id: 4,
    name: "Genesis Bloodlines Matter",
    dec: [
      "Rarer breeds inherit superior attributes For better chances of winning.",
      "Breeding, renting, training, and trading NFTs add depth to your journey.",
    ],
  },
  {
    id: 5,
    name: "Master the Game",
    dec: [
      "Engage in dedicated races, tournaments, and invitational events",
      "Hone your skills in breeding, distances, and track conditions for success.",
    ],
  },
  {
    id: 6,
    name: "Future Expansion & Rewards",
    dec: [
      "Explore expansion options - From expert trainers to private tracks.",
      "Unleash your potential in a dynamic metaverse with endless opportunities.",
    ],
  },
];

export default function Home(props: IProps) {
  return (
    <React.Fragment>
      <div className="home_contain">
        <div className="homebg">
          <video
            src="/Better Version.mp4"
            className="vidiobg"
            width={"100%"}
            height={"100%"}
            loop
            autoPlay
            muted
          />

          
          <div className="welcome">
     <div className="overlayBox">
     <div className="greet-msg">
              <span
              
              >
                Digital Greyhound Racing
              </span>
            </div>

            <div className="subtext">
              <h6>
                Build a kennel of champions and put your dogs to the test
                against others to WIN big crypto racing payouts!
              </h6>
            </div>

            <div className="main-box">
              <div className="boxes-container">
                <a
                  className="box"
                  style={{ top: "190px" }}
                  href="https://mint.betnft.run/"
                  target="_blank"
                >
                  Buy a<span className="dark-green">Greyhound</span>
                </a>
                <Link className="box " style={{ top: "290px" }} to="/race">
                  Start<span className="dark-green">Racing!</span>
                </Link>
                {/* <a className="box " style={{ top: "290px" }} href="/race">
              Start &nbsp;<span className="dark-green">Racing!</span>
            </a> */}
              </div>
            </div>
     </div>
          </div>
        
        </div>

        <div className="blog_contain">
          <h5 className="works">how to Works</h5>
          <h6>Start Racing Today!</h6>
          <span>Navigate the fast lane: Your BetNFT journey begging!</span>

          <div className="card_contain">
            {card_data.map((item) => {
              return (
                <div key={item.id} className="card">
                  <p className="index">{item.id}</p>

                  <h5>{item.name}</h5>

                  <ul>
                    {item.dec.map((item) => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>

          <Link
            to={
              "https://betnft.gitbook.io/welcome-to-gitbook/betnft/getting-started"
            }
            style={{ margin: "0 auto" }}
          >
            <Button
              variant="contained"
              sx={{
                width: "300px",
                height: "65px",

                fontSize: "24px",
                textTransform: "capitalize",
              }}
            >
              GETTING STARTED
            </Button>
          </Link>
        </div>
      </div>
      {/* <Popup>
          <Congratulations/>  
      </Popup> */}
    </React.Fragment>
  );
}
